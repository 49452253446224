div#tooltip {
  background-color: white;
  padding: 5px;
  opacity: 1;
  z-index: 100;
  position: fixed;
}

div#tooltip td {
  padding: 5px;
}

div#tooltip tfoot > td {
  font-weight: bold;
}
